import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { AlertService } from "../../util/alert/alert.service";
import { SharedService } from "../../home/shared.service";
import { ReportService } from "../../report/report.service";
import { Report } from "../../report/report.model";
import { AssessmentService } from '../assessment.service';
import { AssessmentModel } from '../assessment.model';
import { FormGroup, FormControl } from "@angular/forms";
import { IMyDpOptions, IMyCalendarViewChanged, IMyDateModel, IMyDefaultMonth } from 'mydatepicker';
import { DatePipe } from '@angular/common';


@Component({
	selector: "app-candidate-competency",
	templateUrl: "./candidatecompetency.component.html",
	styleUrls: ["./candidatecompetency.component.scss"]
})
export class CandidateCompetencyComponent implements OnInit {

	public stortedUserData: any = localStorage.getItem('selectedUserRole');
	public userId: any = localStorage.getItem('userId');
	public selectedUserRole = [];
	public excelData: any[];
	private serverResultData: Array<any> = [];
	itemsPerPage: number = 10;
	currentPage: number = 1;
	maxNoOfPages: number = 10;
	total: number = 1;
	numPages: number = 1;
	paginatedResults = [];
	filteredData: Array<any> = [];
	originaldata: Array<any> = [];
	showRecentAssessment: boolean = true;
	isDataExist: boolean = false;
	searchFirstName: string = "";
	searchLastName: string = "";
	searchJobProfile: string = "";
	searchOrgCode: string = "";
	searchCompletedDate: any = "";
	isLoading = false;
	public recentAssessmentForm: FormGroup;
	isFiltering: boolean = true;
	public unamePattern = "^[0-9]*$"
	// myDatePickerOptions: IMyDpOptions = {
	// 	dateFormat: 'yyyy',
	// 	width: '150px',
	// 	yearSelector: true,
	// 	markCurrentYear: true
	// };

	allAssessmentParams: any = {
		'scheduledSelfAssFromDate': "",
		'scheduledAssFromDate': "",
		'selfAssCompletedFromDate': "",
		'assCompletedFromDate': "",
		'orgCodeId': "",
		'showCompleted': "3",
	};

	recentAssessmentParams: any = {
		'orgId': "", 'includeChildren': false,
		'jobProfileId': "", 'disciplineId': "",
		'option': "",
		'userId': '',
		'includeCompleted': true,
	};

	constructor(
		private alertService: AlertService,
		private sharedService: SharedService,
		private reportService: ReportService,
		private changeDetectorRef: ChangeDetectorRef,
		private assessmentService: AssessmentService,
		private datePipe: DatePipe
	) {

	}

	ngOnInit() {
		if (this.stortedUserData) {
			this.selectedUserRole = JSON.parse(this.stortedUserData);
		}

		this.recentAssessmentForm = new FormGroup({
			recentAccessment: new FormControl(true)
		});
		this.loadCandidateCompetency();
	}

	pageChanged(page?: number): void {
		if (page) { this.currentPage = page; }
		let end = this.itemsPerPage * this.currentPage;
		let start = end - this.itemsPerPage;
		this.paginatedResults = this.filteredData.slice(start, end);
		this.numPages = Math.ceil(this.filteredData.length / this.itemsPerPage);
		this.numPages = this.numPages == 0 ? 1 : this.numPages;
		this.total = this.filteredData.length;
		this.changeDetectorRef.detectChanges();
	}

	onSorted($event) {
		this.isFiltering = false;
		this.sortData($event)
		this.extendData();
		this.pageChanged(this.currentPage);
	}

	setSortType(criteria: CandidateCompetencySearchCriteria) {
		if (criteria.sortColumn == 'FirstName' || criteria.sortColumn == 'LastName' || criteria.sortColumn == 'JobProfile' || criteria.sortColumn == 'OrgCode') {
			criteria.sortType = 'string';
		}
		else {
			criteria.sortType = 'number';
		}
	}

	sortData(criteria: CandidateCompetencySearchCriteria) {
		if (criteria.sortType == '') {
			this.setSortType(criteria);
		}
		if (criteria.sortDirection === 'desc') {
			this.fillData();
			if (criteria.sortType === 'string') {
				this.filteredData.sort((a, b) => a[criteria.sortColumn].toLowerCase() > b[criteria.sortColumn].toLowerCase() ? -1 : a[criteria.sortColumn].toLowerCase() < b[criteria.sortColumn].toLowerCase() ? 1 : 0)
			}
			else if (criteria.sortType === 'number') {
				this.filteredData.sort((a, b) => a[criteria.sortColumn] > b[criteria.sortColumn] ? -1 : a[criteria.sortColumn] < b[criteria.sortColumn] ? 1 : 0)
			}
		}
		else if (criteria.sortDirection === 'asc') {
			this.fillData();
			if (criteria.sortType === 'string') {
				this.filteredData.sort((a, b) => a[criteria.sortColumn].toLowerCase() < b[criteria.sortColumn].toLowerCase() ? -1 : a[criteria.sortColumn].toLowerCase() > b[criteria.sortColumn].toLowerCase() ? 1 : 0)
			}
			else if (criteria.sortType === 'number') {
				this.filteredData.sort((a, b) => a[criteria.sortColumn] < b[criteria.sortColumn] ? -1 : a[criteria.sortColumn] > b[criteria.sortColumn] ? 1 : 0)
			}
		}
		else {
			this.filteredData = [];
			for (let i = 0; i < this.originaldata.length; i++) {
				this.filteredData.push({
					"Id": this.originaldata[i].Id,
					"AssessmentScore": this.originaldata[i].AssessmentScore,
					"FirstName": this.originaldata[i].FirstName,
					"LastName": this.originaldata[i].LastName,
					"JobProfile": this.originaldata[i].JobProfile,
					"OrgCode": this.originaldata[i].OrgCode,
					"CompletedDate": this.originaldata[i].CompletedDate,
					"AssessmentReport": "",
					"AssessmentModel": this.originaldata[i].AssessmentModel,
					"IsKnowledgeTest": this.originaldata[i].IsKnowledgeTest
				});
			}
			if (this.searchFirstName != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["FirstName"].match(new RegExp(this.searchFirstName, "i"));
				});
			}
			if (this.searchLastName != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["LastName"].match(new RegExp(this.searchLastName, "i"));
				});
			}
			if (this.searchJobProfile != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["JobProfile"].match(new RegExp(this.searchJobProfile, "i"));
				});
			}
			if (this.searchOrgCode != "") {
				this.filteredData = this.filteredData.filter((item: any) => {
					return item["OrgCode"].match(new RegExp(this.searchOrgCode, "i"));
				});
			}

			if (this.searchCompletedDate != "") {
				if (isNaN(this.searchCompletedDate) == false) {
					this.filteredData = this.filteredData.filter((item: any) => {
						var completedDate = item["CompletedDate"].getFullYear().toString()
						return completedDate.match(new RegExp(this.searchCompletedDate, "i"));
					});
				}
				// let formattedSearchCompletedDate = this.datePipe.transform(this.searchCompletedDate,"MM/dd/yyyy");
				// 	if(formattedSearchCompletedDate != null) {
				// 		this.filteredData = this.filteredData.filter((item: any) => {
				// 			let completedDate = this.datePipe.transform(item["CompletedDate"],"MM/dd/yyyy");
				// 			//let completedDate = item["CompletedDate"]
				// 			return completedDate.match(new RegExp(formattedSearchCompletedDate, "i"));
				// 	});
				// }
			}
		}
	}

	// onValueChange(event: IMyDateModel) {
	// 	let temp = event.jsdate;		
	// 	this.searchCompletedDate = temp
	// 	this.filter()
	// }

	filter() {
		this.isFiltering = true;
		for (var i = 0; i < this.originaldata.length; i++) {
			this.filteredData = this.originaldata.map(x => Object.assign({}, x));
		}
		if (this.searchFirstName != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["FirstName"].match(new RegExp(this.searchFirstName, "i"));
			});
		}
		if (this.searchLastName != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["LastName"].match(new RegExp(this.searchLastName, "i"));
			});
		}
		if (this.searchJobProfile != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["JobProfile"].match(new RegExp(this.searchJobProfile, "i"));
			});
		}
		if (this.searchOrgCode != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["OrgCode"].match(new RegExp(this.searchOrgCode, "i"));
			});
		}

		if (this.searchCompletedDate != null) {
			if (isNaN(this.searchCompletedDate) == false) {
				this.filteredData = this.filteredData.filter((item: any) => {
					var completedDate = item["CompletedDate"].getFullYear().toString()
					return completedDate.match(new RegExp(this.searchCompletedDate, "i"));
				});
			}

			// let formattedSearchCompletedDate = this.datePipe.transform(this.searchCompletedDate,"MM/dd/yyyy");
			// 	if(formattedSearchCompletedDate != null) {
			// 		this.filteredData = this.filteredData.filter((item: any) => {
			// 			let completedDate = this.datePipe.transform(item["CompletedDate"],"MM/dd/yyyy");						
			// 			return completedDate.match(new RegExp(formattedSearchCompletedDate, "i"));
			// 	});
			// }
		}


		this.extendData();
		this.pageChanged(1);
		this.isFiltering = false;
		if (this.filteredData.length > 0) {
			this.isDataExist = true
		} else {
			this.isDataExist = false
		}
	}

	showMostRecentAssessment(e) {
		if (e.target.checked) {
			this.showRecentAssessment = true;
		}
		else {
			this.showRecentAssessment = false;
		}
		this.clearFilters();
		this.getAssessments();
	}

	clearFilters() {
		this.searchFirstName = "";
		this.searchLastName = "";
		this.searchJobProfile = "";
		this.searchOrgCode = "";
		this.searchCompletedDate = null;
		this.isDataExist = false
	}

	public loadCandidateCompetency() {
		this.assessmentService.setSelectedOrgCodeId(null);
		this.getAssessments();
	}

	getAssessments() {
		this.isLoading = true;
		this.isFiltering = true;
		if (!this.showRecentAssessment) {
			if (this.selectedUserRole[0][1] == 'Supervisor') {
				this.allAssessmentParams['supervisorId'] = localStorage.getItem('userId');
			}
			this.assessmentService.getAllAssessments(this.allAssessmentParams)
				.subscribe(
				data => {
					this.filteredData = [];
					this.originaldata = [];
					for (var i = 0; i < data.Assessments.length; i++) {

						let tempAssessment = data.Assessments[i]
						let assessment: AssessmentModel = new AssessmentModel(tempAssessment.Id)
						assessment.assessmentScore = tempAssessment.AssessmentScore == null ? 0 : tempAssessment.AssessmentScore.toFixed(0);
						assessment.userName = tempAssessment.FirstName + " " + tempAssessment.LastName;
						assessment.firstName = tempAssessment.FirstName
						assessment.lastName = tempAssessment.LastName
						assessment.userId = tempAssessment.Id
						assessment.jobProfileDesc = tempAssessment.jobProfile;
						if (tempAssessment.AssessmentCompletedDate == null) {
							assessment.assessmentCompletedDate = null
						} else {
							assessment.assessmentCompletedDate = new Date(new Date(tempAssessment.AssessmentCompletedDate).getUTCFullYear(), new Date(tempAssessment.AssessmentCompletedDate).getUTCMonth(), new Date(tempAssessment.AssessmentCompletedDate).getUTCDate());
						}
						if (tempAssessment.SelfAssessmentCompletedDate == null) {
							assessment.selfAssessmentCompletedDate = null
						} else {
							assessment.selfAssessmentCompletedDate = new Date(new Date(tempAssessment.SelfAssessmentCompletedDate).getUTCFullYear(), new Date(tempAssessment.SelfAssessmentCompletedDate).getUTCMonth(), new Date(tempAssessment.SelfAssessmentCompletedDate).getUTCDate());
						}
						assessment.candidateStrengths = tempAssessment.CandidateStrengths
						assessment.isKnowledgeTest = tempAssessment.IsKnowledgeTest
						this.filteredData.push({
							"Id": data.Assessments[i].UserId,
							"AssessmentScore": assessment.assessmentScore,
							"FirstName": data.Assessments[i].FirstName,
							"LastName": data.Assessments[i].LastName,
							"JobProfile": data.Assessments[i].jobProfile,
							"OrgCode": data.Assessments[i].orgCode,
							"CompletedDate": assessment.assessmentCompletedDate,
							"AssessmentReport": "",
							"AssessmentModel": assessment,
							"IsKnowledgeTest": tempAssessment.IsKnowledgeTest
						});

						this.originaldata.push({
							"Id": data.Assessments[i].UserId,
							"AssessmentScore": assessment.assessmentScore,
							"FirstName": data.Assessments[i].FirstName,
							"LastName": data.Assessments[i].LastName,
							"JobProfile": data.Assessments[i].jobProfile,
							"OrgCode": data.Assessments[i].orgCode,
							"CompletedDate": assessment.assessmentCompletedDate,
							"AssessmentReport": "",
							"AssessmentModel": assessment,
							"IsKnowledgeTest": tempAssessment.IsKnowledgeTest
						});
					}

					this.isLoading = false;
					this.total = this.filteredData.length;
					this.isFiltering = false;
					this.extendData();
					this.pageChanged();
					if (this.filteredData.length > 0) {
						this.isDataExist = true
					} else {
						this.isDataExist = false
					}
				},
				error => {
					this.isLoading = false;
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
				);
		}
		else {
			if (this.selectedUserRole[0][1] == 'Supervisor') {
				this.recentAssessmentParams['supervisorId'] = localStorage.getItem('userId');
			}			
			this.assessmentService.getAllAssessmentBySelectedParameters(this.recentAssessmentParams)
				.subscribe(
				data => {
					this.filteredData = [];
					this.originaldata = [];
					for (var i = 0; i < data.userList.length; i++) {
						let tempAssessment = data.userList[i]
						let assessment: AssessmentModel = new AssessmentModel(tempAssessment.lastCompletedassessmentId)
						assessment.assessmentScore = tempAssessment.AssessmentScore == null ? 0 : tempAssessment.AssessmentScore.toFixed(0);
						assessment.userName = tempAssessment.FirstName + " " + tempAssessment.LastName;
						assessment.firstName = tempAssessment.FirstName
						assessment.lastName = tempAssessment.LastName
						assessment.userId = tempAssessment.Id
						assessment.jobProfileDesc = tempAssessment.assessmentJobProfile;
						if (tempAssessment.LastAssessmentCompletedDate == null) {
							assessment.assessmentCompletedDate = null
						} else {
							assessment.assessmentCompletedDate = new Date(new Date(tempAssessment.LastAssessmentCompletedDate).getUTCFullYear(), new Date(tempAssessment.LastAssessmentCompletedDate).getUTCMonth(), new Date(tempAssessment.LastAssessmentCompletedDate).getUTCDate());
						}
						if (tempAssessment.SelfAssessmentCompletedDate == null) {
							assessment.selfAssessmentCompletedDate = null
						} else {
							assessment.selfAssessmentCompletedDate = new Date(new Date(tempAssessment.SelfAssessmentCompletedDate).getUTCFullYear(), new Date(tempAssessment.SelfAssessmentCompletedDate).getUTCMonth(), new Date(tempAssessment.SelfAssessmentCompletedDate).getUTCDate());
						}
						assessment.candidateStrengths = tempAssessment.CandidateStrengths
						assessment.isKnowledgeTest = tempAssessment.IsKnowledgeTest
						this.filteredData.push({
							"Id": data.userList[i].Id,
							"AssessmentScore": assessment.assessmentScore,
							"FirstName": data.userList[i].FirstName,
							"LastName": data.userList[i].LastName,
							"JobProfile": data.userList[i].assessmentJobProfile,
							"OrgCode": data.userList[i].assessmentOrgCode,
							"CompletedDate": assessment.assessmentCompletedDate,
							"AssessmentReport": "",
							"AssessmentModel": assessment,
							"IsKnowledgeTest": tempAssessment.IsKnowledgeTest,
							"color": i % 2 == 0 ? "" : "rgba(0, 0, 0, 0.05)"
						});

						this.originaldata.push({
							"Id": data.userList[i].Id,
							"AssessmentScore": assessment.assessmentScore,
							"FirstName": data.userList[i].FirstName,
							"LastName": data.userList[i].LastName,
							"JobProfile": data.userList[i].assessmentJobProfile,
							"OrgCode": data.userList[i].assessmentOrgCode,
							"CompletedDate": assessment.assessmentCompletedDate,
							"AssessmentReport": "",
							"AssessmentModel": assessment,
							"IsKnowledgeTest": tempAssessment.IsKnowledgeTest,
							"color": i % 2 == 0 ? "" : "rgba(0, 0, 0, 0.05)"
						});
					}

					this.isLoading = false;
					this.isFiltering = false;
					this.total = this.filteredData.length;
					this.pageChanged();
					if (this.filteredData.length > 0) {
						this.isDataExist = true
					} else {
						this.isDataExist = false
					}
				},
				error => {
					this.isLoading = false;
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
				);
		}
	}

	public performAssessmentClickEvent(assessment) {
		localStorage.setItem('selectedAsseseeName', assessment.userName)
		localStorage.setItem('selectedAsseseeUserId', assessment.userId)
		this.assessmentService.setSelectedAssessmentModel(assessment);
		this.assessmentService.setGenerate(true)
		this.sharedService.change('CandidateAssessment');
	}


	public fillData() {
		if (this.filteredData.length > 1) {
			let firstName = this.filteredData[0]["FirstName"];
			let lastName = this.filteredData[0]["LastName"];
			let jobProfile = this.filteredData[0]["JobProfile"];
			let orgCode = this.filteredData[0]["OrgCode"];
			var completedDate = this.filteredData[0]["CompletedDate"];
			for (let i = 1; i < this.filteredData.length; i++) {
				if (this.filteredData[i]["FirstName"] == '') {
					this.filteredData[i]["FirstName"] = firstName;
					this.filteredData[i]["LastName"] = lastName;
					if (this.filteredData[i]["JobProfile"] == '') {
						this.filteredData[i]["JobProfile"] = jobProfile
					}
					if (this.filteredData[i]["OrgCode"] == '') {
						this.filteredData[i]["OrgCode"] = orgCode
					}
					//this.filteredData[i]["JobProfile"] = this.filteredData[i]["JobProfile"];
					//this.filteredData[i]["OrgCode"] = this.filteredData[i]["OrgCode"];
					this.filteredData[i]["CompletedDate"] = this.filteredData[i]["CompletedDate"];
				} else {
					firstName = this.filteredData[i]["FirstName"];
					lastName = this.filteredData[i]["LastName"];
					jobProfile = this.filteredData[i]["JobProfile"];
					orgCode = this.filteredData[i]["OrgCode"];
					completedDate = this.filteredData[i]["CompletedDate"];
				}
			}
		}
	}

	extendData() {
		if (this.filteredData.length > 1) {
			let currentUserId = this.filteredData[0]["Id"];
			let currentJobProfile = this.filteredData[0]["JobProfile"];
			let currentOrgCode = this.filteredData[0]["OrgCode"];
			this.filteredData[0]["color"] = "";
			if (!this.showRecentAssessment) {
				for (let i = 1; i < this.filteredData.length; i++) {
					if (this.filteredData[i]["Id"] != '') {
						if (this.filteredData[i]["Id"] === currentUserId) {
							this.filteredData[i]["FirstName"] = '';
							this.filteredData[i]["LastName"] = '';							
							if (this.filteredData[i]["JobProfile"] === currentJobProfile) {
								this.filteredData[i]["JobProfile"] = '';
							} else {
								currentJobProfile = this.filteredData[i]["JobProfile"];
							}
							if (this.filteredData[i]["OrgCode"] === currentOrgCode) {
								this.filteredData[i]["OrgCode"] = '';
							} else {
								currentOrgCode = this.filteredData[i]["OrgCode"];
							}
							this.filteredData[i]["color"] = this.filteredData[i]["color"]
						} else {
							currentUserId = this.filteredData[i]["Id"];
							currentJobProfile = this.filteredData[i]["JobProfile"];
							currentOrgCode = this.filteredData[i]["OrgCode"];
							this.filteredData[i]["color"] = this.filteredData[i - 1]["color"] == "" ? "rgba(0, 0, 0, 0.05)" : ""
						}
					}
				}
			}
			else {
				for (let i = 1; i < this.filteredData.length; i++) {
					this.filteredData[i]["color"] = this.filteredData[i - 1]["color"] == "" ? "rgba(0, 0, 0, 0.05)" : ""					
				}
			}
		}
	}

	exportToExcel() {
		var data = []
		if (this.filteredData.length > 0) {
			this.filteredData.forEach(item => {
				var temp = {
					"First Name": item.FirstName,
					"Last Name": item.LastName,
					"Job Profile": item.JobProfile,
					"Org Code": item.OrgCode,
					"Completed Date": item.CompletedDate,
					"Knowledge Check Only": item.IsKnowledgeTest === 1 ? 'Yes' : 'No',
					"Score": item.AssessmentScore,
				}
				data.push(temp)
			});

			let outputName = "Candidate Competency"
		
		this.reportService.exportAsExcelFile(data, outputName);
		}
	}

	//navigate to candidate competency graphical view report component
	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

}

export class CandidateCompetencySearchCriteria {
	sortColumn: string;
	sortDirection: string;
	sortType: string;
}
