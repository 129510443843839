import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators, FormsModule } from "@angular/forms";

import { KnowledgeService } from './knowledge.service';
import { AlertService } from '../../util/alert/alert.service';
import { UserService } from '../../user/user.service';
import { SharedService } from '../../home/shared.service';
import { OrgcodeService } from '../../orgcode/orgcode.service';
import { JobProfileService } from '../../jobprofile/jobprofile.service';

import { Knowledge } from './knowledge.model';
import { KnowledgeAnswer } from './knowledgeanswer';
import { JobProfile } from '../../jobprofile/jobprofile.model'
import { CoreCompetency } from '../corecompetency/corecompetency.model';
import { Task } from '../task/task.model'

import { TagInputModule } from 'ngx-chips';
import { KnowledgeTagService } from '../../tag/knowledgetag/knowledgetag.service';
import { NoWhitespaceDirective } from "../../util/validator/nowhitespace.directive"
declare var require: any
const uuidv1 = require('uuid/v1');

declare var $: any;

@Component({
	selector: 'app-add-knowledge',
	templateUrl: './addknowledge.component.html',
	styleUrls: ['./addknowledge.component.scss']
})
export class AddKnowledgeComponent implements OnInit, AfterViewInit {

	@ViewChild('fileInput', { static: true }) questionFileInputVariable: ElementRef;
	@ViewChild('file', { static: true }) answerFileInputVariable: ElementRef;

	addKnowledgeForm: FormGroup;
	arrayOfStrings: string[];
	showTypeDiv: boolean = false;
	showBooleanDiv: boolean = false;
	showAnswers: boolean = false;
	showRadioBtns: boolean = false;
	isSelected: boolean;
	showVisualType: boolean = false;
	showVisualQuestion: boolean = false;
	clickVisualQuestion: boolean = false;
	isVisualQueSingleSelected: boolean = false;
	isVisualQueMultipleSelected: boolean = false;
	showVisualAnswers: boolean = false;
	showImage: boolean = false;
	checkbox: boolean;
	showQuestionImage: boolean = false;
	public orgList: Array<any> = [];
	selectedOrgId: string;
	public autocompleteItemsAsObjects = [];
	public selectedTags = [];
	public isSelectedMultiple: boolean;
	public ansExists: boolean;
	public editKnowledgeAnswer: Array<KnowledgeAnswer> = [];
	public answerArray: Array<KnowledgeAnswer> = [];
	isBooleanSelected: boolean = false;
	checkedRadioIndex: number = -1;
	public selectedMenuItem = localStorage.getItem('selectedMenuItem');
	public imagePath;
	imgURL: any = '';
	selectedFile: File;
	fileList: File[] = [];
	listOfFiles: any[] = [];
	filesToUpload: Array<File> = [];
	public message: string;
	questionImageUrl: any;
	isLoading: boolean = false;
	answerList: any = [];

	constructor(private formBuilder: FormBuilder, private userService: UserService, private alertService: AlertService,
		private knowledgeService: KnowledgeService, private sharedService: SharedService,
		private orgcodeService: OrgcodeService, private jobProfileService: JobProfileService,
		private knowledgeTagService: KnowledgeTagService) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
		//this.filesToUpload = [];
		this.knowledgeService.getAllKnowledgeElements()
			.subscribe(
			data => {
				this.arrayOfStrings = [];
				for (var i = 0; i < data.knowledge.length; i++) {
					this.arrayOfStrings.push(data.knowledge[i].Knowledge);
				}
				$("#knowledge").autocomplete({ source: this.arrayOfStrings });
			},
			error => {
			}
			);
		if (this.selectedMenuItem == 'AddJobProfile') {
			let jpOrgId = this.jobProfileService.getSelectedJobProfileForEdit().orgCodeId;
			this.orgcodeService.getActiveOrgCodesAboveOrgCode(jpOrgId)
				.subscribe(
				data => {
					this.orgList = [];
					for (var i = 0; i < data.orgCodeList.length; i++) {
						this.orgList.push({ id: data.orgCodeList[i].Id, text: data.orgCodeList[i].Description });
					}
					// this.selectedOrgId = null;
				},
				error => {
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
				);
		}
		else {
			this.orgcodeService.getActiveOrgcodes()
				.subscribe(
				data => {
					this.orgList = [];
					for (var i = 0; i < data.orgcode.length; i++) {
						this.orgList.push({ id: data.orgcode[i].Id, text: data.orgcode[i].Description });
					}
					this.selectedOrgId = null;
				},
				error => {
				}
				);
		}
	}

	valid() {
		let result = false;
		if (this.addKnowledgeForm.get("knowledge").status == "VALID" && !this.addKnowledgeForm.value.isTestQuestion) {
			result = true;
		}
		else if (this.addKnowledgeForm.get("knowledge").status == "VALID" && this.addKnowledgeForm.value.isTestQuestion) {
			if ((this.addKnowledgeForm.value.type == "" && this.addKnowledgeForm.value.type == null) ||
				(this.addKnowledgeForm.value.visualtype == "" && this.addKnowledgeForm.value.visualtype == null)) {
				result = false;
			}
			else {
				let answerControls = <FormArray>this.addKnowledgeForm.controls['answers'];
				let visualAnswerControls = <FormArray>this.addKnowledgeForm.controls['visualAnswers'];
				let count = 0;
				let emptyFieldFound = false;
				let imageValid = false
				if (answerControls.length != 0) {
					for (var i = 0; i < answerControls.length; i++) {
						// console.log(this.addKnowledgeForm.controls.answers.value[i].isCorrect);
						// console.log(" this.addKnowledgeForm.controls.answers.value[i].answer " +  this.addKnowledgeForm.controls.answers.value[i].answer)
						if (this.addKnowledgeForm.controls.answers.value[i].answer.trim().length == 0) {
							emptyFieldFound = true;
							break;
						}
						if (this.addKnowledgeForm.value.type == "Single") {
							let value = (this.checkedRadioIndex != -1) ? 1 : 0;
							if (value == 1) {
								count++;
							}
						}
						else {
							let value = (this.addKnowledgeForm.controls.answers.value[i].isCorrect === true) ? 1 : 0;
							if (value == 1) {
								count++;
							}
						}
					}
				} else if (visualAnswerControls.length != 0) {
					for (var i = 0; i < visualAnswerControls.length; i++) {
						if (this.addKnowledgeForm.controls.visualAnswers.value[i].visualTextAnswer.trim().length == 0) {
							if (this.showImage == false) {
								emptyFieldFound = true;
								break;
							} else {
								if (visualAnswerControls.length != this.fileList.length) {
									imageValid = true;
								}
							}
						}
						if (this.addKnowledgeForm.value.visualtypeOpt == "Single") {
							let value = (this.checkedRadioIndex != -1) ? 1 : 0;
							if (value == 1) {
								count++;
							}
						}
						else {
							let value = (this.addKnowledgeForm.controls.visualAnswers.value[i].isCorrectAnswer === true) ? 1 : 0;
							if (value == 1) {
								count++;
							}
						}
					}
				} else {
					//nothing to do here
				}

				if (!emptyFieldFound && count > 0) {
					if (this.showImage == false) {
						result = true;
					} else {
						if (imageValid && count > 0) {
							result = false;
						} else {
							result = true;
						}
					}
				}
				if (count < 2 && this.addKnowledgeForm.value.type == "Multiple") {
					result = false;
				}
				if (count < 2 && this.addKnowledgeForm.value.visualtypeOpt == "Multiple") {
					result = false;
				}
				if (answerControls.length < 2 && this.addKnowledgeForm.value.type == "Single") {
					result = false;
				}
				if (visualAnswerControls.length < 2 && this.addKnowledgeForm.value.visualtypeOpt == "Single") {
					result = false;
				}
				if (this.addKnowledgeForm.value.type == "Boolean" && this.isBooleanSelected) {
					result = true;
				}
			}
		}
		return result;
	}

	setBoolean() {
		this.isBooleanSelected = true;
	}


	ngOnInit() {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		this.loadActiveKnowledgeTags();
		this.addKnowledgeForm = this.formBuilder.group({
			knowledge: new FormControl(null, Validators.required),
			isTestQuestion: new FormControl(null),
			isVisualQuestion: new FormControl(null),
			type: new FormControl(null),
			visualtype: new FormControl(false),
			visualtypeOpt: new FormControl(null),
			orgId: new FormControl(null),
			trueorfalse: new FormControl(null),
			answers: this.formBuilder.array([]),
			fileType: new FormControl(''),
			isImageAttached: new FormControl(null),
			visualAnswers: this.formBuilder.array([]),
			knowledgeTag: new FormControl(null)
		});
	}

	initAnswer() {
		return this.formBuilder.group({
			answer: ['', Validators.required],
			isCorrect: ['']
		});
	}

	initVisualAnswer() {
		return this.formBuilder.group({
			answerFileType: [''],
			visualTextAnswer: ['', Validators.required],
			isCorrectAnswer: [''],
			photos: ['']
		});
	}

	addAnswer() {
		if (this.clickVisualQuestion == true) {
			if (this.fileList.length == 0) {
				this.showImage = false
			} else {
				this.valid()
				this.showImage = true
			}
			const control = <FormArray>this.addKnowledgeForm.controls['visualAnswers'];
			control.push(this.initVisualAnswer());
		} else {
			const control = <FormArray>this.addKnowledgeForm.controls['answers'];
			control.push(this.initAnswer());
		}
	}

	removeAnswer(i: number) {
		const control = <FormArray>this.addKnowledgeForm.controls['answers'];
		control.removeAt(i);
		if (this.checkedRadioIndex == i) {
			this.checkedRadioIndex = -1;
		}
	}

	removeVisualAnswer(i: number) {
		const control = <FormArray>this.addKnowledgeForm.controls['visualAnswers'];
		if (this.addKnowledgeForm.controls.visualAnswers.value[i].answerFileType) {
			this.fileList.splice(i, 1);
		}
		if (this.checkedRadioIndex == i) {
			this.checkedRadioIndex = -1;
		}
		control.removeAt(i);
	}

	removeall() {
		const control = <FormArray>this.addKnowledgeForm.controls['answers'];
		let count = control.length;
		for (var i = 0; i < count; i++) {
			control.removeAt(0);
		}
		this.checkedRadioIndex = -1;
	}

	removeAllVisualAnswer() {
		const control = <FormArray>this.addKnowledgeForm.controls['visualAnswers'];
		let count = control.length;
		for (var i = 0; i < count; i++) {
			control.removeAt(0);
		}
		this.checkedRadioIndex = -1;
		for (var i = 0; i < this.fileList.length; i++) {
			this.fileList.splice(i, 1);
		}
	}

	showType(e) {
		if (e.target.checked) {
			this.showTypeDiv = true;
			this.showAnswers = false;
			this.showBooleanDiv = false;
			this.showRadioBtns = false;
			this.showVisualQuestion = true;
			this.addKnowledgeForm.get('type').setValidators([Validators.required]);
		} else {
			this.showTypeDiv = false;
			this.showAnswers = false;
			this.showBooleanDiv = false;
			this.showRadioBtns = false;
			this.showVisualQuestion = false;
			this.showVisualType = false;
			this.showVisualAnswers = false;
			this.checkbox = false;
			this.addKnowledgeForm.get('type').setValidators([]);
			this.removeall();
			this.addKnowledgeForm.get("type").setValue("");
			this.addKnowledgeForm.get("visualtypeOpt").setValue("");
			this.addKnowledgeForm.get("trueorfalse").setValue("");
			this.addKnowledgeForm.get('trueorfalse').setValidators([]);
		}
		this.checkedRadioIndex = -1;
		this.addKnowledgeForm.get('type').updateValueAndValidity();
	}

	showVisualTypeOnChange(e) {
		if (e.target.checked) {
			this.showTypeDiv = false;
			this.showVisualQuestion = true;
			this.showVisualType = true
			this.clickVisualQuestion = true
			this.showAnswers = false;
			this.showBooleanDiv = false;
			this.showRadioBtns = false;
			this.showAnswers = false;
			this.addKnowledgeForm.get("type").setValue("");
			this.removeall();
		} else {
			this.showTypeDiv = true;
			this.showVisualType = false
			this.clickVisualQuestion = false
			this.showAnswers = false;
			this.showBooleanDiv = false;
			this.showRadioBtns = false;
			this.showVisualAnswers = false
			this.showQuestionImage = false
			this.addKnowledgeForm.get("visualtypeOpt").setValue("");
			this.removeAllVisualAnswer()
		}
		this.checkedRadioIndex = -1;
	}


	setIsCorrect(e, i) {
		let answerControls = <FormArray>this.addKnowledgeForm.controls['answers'];
		for (var j = 0; j < answerControls.length; j++) {
			if (i == j && e.target.checked) {
				this.checkedRadioIndex = i;
				this.addKnowledgeForm.controls.answers.value[j].isCorrect = true;
			} else {
				this.addKnowledgeForm.controls.answers.value[j].isCorrect = false;
			}

		}
	}

	setIsCorrectVisualAnswer(e, i) {
		let answerControls = <FormArray>this.addKnowledgeForm.controls['visualAnswers'];
		for (var j = 0; j < answerControls.length; j++) {
			if (i == j && e.target.checked) {
				this.checkedRadioIndex = i;
				this.addKnowledgeForm.controls.visualAnswers.value[j].isCorrect = true;
			} else {
				this.addKnowledgeForm.controls.visualAnswers.value[j].isCorrect = false;
			}

		}
	}

	removeAllCorrectAnswers() {
		const control = <FormArray>this.addKnowledgeForm.controls['answers'];
		let count = control.length;
		for (var i = 0; i < count; i++) {
			if (this.addKnowledgeForm.controls.answers.value[i].isCorrect) {
				control.controls[i].get("isCorrect").setValue(false)
			}
		}
		this.checkedRadioIndex = -1;
	}

	removeAllVisualCorrectAnswers() {
		const control = <FormArray>this.addKnowledgeForm.controls['visualAnswers'];
		let count = control.length;
		for (var i = 0; i < count; i++) {
			if (this.addKnowledgeForm.controls.visualAnswers.value[i].isCorrectAnswe) {
				control.controls[i].get("isCorrectAnswer").setValue(false)
			}
		}
		this.checkedRadioIndex = -1;
	}

	showAnswer(e) {
		if (e.target.value == "Single" || e.target.value == "Multiple") {
			this.removeAllCorrectAnswers()
			this.showAnswers = true;
			this.showBooleanDiv = false;
			if (e.target.value == "Single") {
				this.showRadioBtns = true;
			} else {
				this.showRadioBtns = false;
			}
			this.addKnowledgeForm.get('trueorfalse').setValidators([]);
		} else {
			this.removeall()
			this.showAnswers = false;
			this.showBooleanDiv = true;
			this.showRadioBtns = false;
			this.addKnowledgeForm.get('trueorfalse').setValidators([Validators.required]);
		}
		// this.checkedRadioIndex = -1;
		this.isBooleanSelected = false;
		this.addKnowledgeForm.get("trueorfalse").setValue("");
		this.addKnowledgeForm.get('trueorfalse').updateValueAndValidity();
	}

	showVisualAnswer(e) {
		if (e.target.value == "Single" || e.target.value == "Multiple") {
			this.showVisualAnswers = true
			this.showAnswers = true;
			this.removeAllVisualCorrectAnswers()
			if (e.target.value == "Single") {
				this.showRadioBtns = true;
			} else {
				this.showRadioBtns = false;
			}
			this.addKnowledgeForm.get('trueorfalse').setValidators([]);
		} else {
			this.showVisualAnswers = false
			this.showAnswers = false;
			this.removeAllVisualAnswer()
		}
		// this.checkedRadioIndex = -1;
		this.addKnowledgeForm.get("trueorfalse").setValue("");
	}

	preview(event: any, i) {
		this.alertService.clear();
		let answerControls = <FormArray>this.addKnowledgeForm.controls['visualAnswers'];
		let fileType = event.target.files[0].name.split('.').pop()
		if (this.checkForValidFile(fileType)) {
			this.showImage = true
			for (var j = 0; j < answerControls.length; j++) {
				if (i == j && event.target.files && event.target.files[0]) {
					var selectedFile = event.target.files[0];
					if (this.addKnowledgeForm.controls.visualAnswers.value[i].answerFileType) {
						this.fileList.splice(i, 1);
					}
					this.fileList.push(selectedFile);
					answerControls.controls[i].get("visualTextAnswer").setValue("")
					answerControls.controls[i].get("answerFileType").setValue(event.target.files[0].name.split('.').pop())
					var reader = new FileReader();
					reader.onload = (event: any) => {
						this.imgURL = event.target.result;
						answerControls.controls[i].get("photos").setValue(this.imgURL)
					}
					reader.readAsDataURL(event.target.files[0]);
				}
			}
		} else {
			this.fileList.splice(i, 1);
			answerControls.controls[i].get("photos").setValue("")
			event.srcElement.value = null;
			this.alertService.error('Inserted file(s) are invalid.');
		}
	}

	fileChangeEvent(fileInput: any) {
		this.alertService.clear();
		let filetype = fileInput.target.files[0].name.split('.').pop()
		if (this.checkForValidFile(filetype)) {
			this.showQuestionImage = true
			this.filesToUpload = <Array<File>>fileInput.target.files;
			var reader = new FileReader();
			this.addKnowledgeForm.get("fileType").setValue(fileInput.target.files[0].name.split('.').pop());
			reader.onload = (event: any) => {
				this.questionImageUrl = event.target.result;
			}
			reader.readAsDataURL(fileInput.target.files[0]);
		} else {
			this.alertService.error('Inserted file is invalid.');
			this.questionFileInputVariable.nativeElement.value = ""
			this.showQuestionImage = false
			this.addKnowledgeForm.get("fileType").setValue("");
		}
	}

	private checkForValidFile(ext) {
		switch (ext.toLowerCase()) {
			case 'jpg':
			case 'gif':
			case 'bmp':
			case 'png':
				return true;
		}
		return false;
	}


	ngAfterViewInit() {

	}

	saveAndNew() {
		this.answerArray = [];
		this.ansExists = true;
		let answerControls = <FormArray>this.addKnowledgeForm.controls['answers'];
		let visualAnswerControls = <FormArray>this.addKnowledgeForm.controls['visualAnswers'];
		let count = 0;
		if (answerControls.length != 0) {
			for (var i = 0; i < answerControls.length; i++) {
				let value = this.addKnowledgeForm.controls.answers.value[i].isCorrect === true ? 1 : 0;
				let answer = this.addKnowledgeForm.controls.answers.value[i].answer;
				let fullAnswer = new KnowledgeAnswer(this.addKnowledgeForm.controls.answers.value[i].answer, value);
				if (this.answerArray.length == 0) {
					this.answerArray.push(fullAnswer);
				} else if (!this.isAnswerAlreadyExists(this.answerArray, answer)) {
					this.answerArray.push(fullAnswer);
				} else {
					count++;
				}
			}
		} else if (visualAnswerControls.length != 0) {
			for (var i = 0; i < visualAnswerControls.length; i++) {
				let value = this.addKnowledgeForm.controls.visualAnswers.value[i].isCorrectAnswer === true ? 1 : 0;
				let answer = this.addKnowledgeForm.controls.visualAnswers.value[i].visualTextAnswer;
				let fullAnswer = new KnowledgeAnswer(this.addKnowledgeForm.controls.visualAnswers.value[i].visualTextAnswer, value);
				if (this.answerArray.length == 0) {
					this.answerArray.push(fullAnswer);
				} else if (!this.isAnswerAlreadyExists(this.answerArray, answer)) {
					this.answerArray.push(fullAnswer);
				} else {
					count++;
				}
			}

		} else {

		}
		if (count > 0) {
			this.ansExists = false
		}
		if (this.ansExists) {
			this.isLoading = true
			let orgId = null;
			if (this.addKnowledgeForm.get("orgId").value != null) {
				if (this.addKnowledgeForm.get("orgId").value.length > 0) {
					orgId = this.addKnowledgeForm.get("orgId").value[0].id;
				}
			}

			let isTestQue = this.addKnowledgeForm.value.isTestQuestion === true ? 1 : 0;
			let isVisualQuestion = this.addKnowledgeForm.value.isVisualQuestion === true ? 1 : 0;
			let knowledge = new Knowledge(this.addKnowledgeForm.value.knowledge, isTestQue);
			knowledge.isVisualQuestion = isVisualQuestion
			if (this.addKnowledgeForm.value.type != "") {
				knowledge.type = this.addKnowledgeForm.value.type;
			} else if (this.addKnowledgeForm.value.visualtypeOpt != "") {
				knowledge.type = this.addKnowledgeForm.value.visualtypeOpt
			} else {
				knowledge.type = ""
			}
			knowledge.correctAnswer = this.addKnowledgeForm.value.trueorfalse === "true" ? 1 : 0;
			knowledge.correctAnswer = this.addKnowledgeForm.value.type != "Boolean" ? null : knowledge.correctAnswer;
			knowledge.orgCode = orgId

			knowledge.tagList = this.selectedTags;
			if (this.filesToUpload.length != 0) {
				knowledge.isImageAttached = 1
			} else {
				knowledge.isImageAttached = 0
			}
			if (this.filesToUpload.length != 0) {
				knowledge.fileType = this.addKnowledgeForm.value.fileType
			} else {
				knowledge.fileType = this.addKnowledgeForm.value.fileType
			}

			let ansArray: Array<KnowledgeAnswer> = [];
			let answerControls = <FormArray>this.addKnowledgeForm.controls['answers'];
			let visualAnswerControls = <FormArray>this.addKnowledgeForm.controls['visualAnswers'];
			let count = 0;
			if (answerControls.length != 0) {
				for (var i = 0; i < answerControls.length; i++) {
					let value = this.addKnowledgeForm.controls.answers.value[i].isCorrect === true ? 1 : 0;
					if (value == 1) {
						count++;
					}
					//console.log(this.addKnowledgeForm.controls.answers.value[i].answer + " "+ value);
					let curAnswer = new KnowledgeAnswer(this.addKnowledgeForm.controls.answers.value[i].answer, value);
					ansArray.push(curAnswer);
				}
			} else if (visualAnswerControls.length != 0) {
				for (var i = 0; i < visualAnswerControls.length; i++) {
					let value = this.addKnowledgeForm.controls.visualAnswers.value[i].isCorrectAnswer === true ? 1 : 0;
					if (value == 1) {
						count++;
					}
					let answer = this.addKnowledgeForm.controls.visualAnswers.value[i].visualTextAnswer
					let fileType = this.addKnowledgeForm.controls.visualAnswers.value[i].answerFileType
					if (answer != '') {
						let curAnswer = new KnowledgeAnswer(answer, value, '', '', '');
						ansArray.push(curAnswer);
					} else {
						let curAnswer = new KnowledgeAnswer(answer, value);
						curAnswer.answerFileType = fileType
						ansArray.push(curAnswer);
					}
				}
			} else {

			}
			if (count > 0) {
				this.isSelected = true;
			} else {
				this.isSelected = false;
				if (knowledge.type == "Boolean" || knowledge.isTestQuestion == 0) {
					this.isSelected = true;
				}
				else if (knowledge.type == "Single" && this.checkedRadioIndex != -1) {
					ansArray[this.checkedRadioIndex].isCorrect = 1;
					this.isSelected = true;
				}
			}
			if (count < 2 && knowledge.type == "Multiple") {
				this.isSelectedMultiple = false;
			} else {
				this.isSelectedMultiple = true;
			}
			if (this.isSelected) {
				if (this.isSelectedMultiple) {
					this.answerList = []
					this.knowledgeService.addKnowledge(knowledge, ansArray, [], [], '')
						.subscribe(
						data => {
							if (this.fileList.length != 0) {
								this.answerList = data.answerList
								this.knowledgeService.makeFileRequests([], this.fileList, data.knowledgeId, this.answerList)
									.subscribe(
									dataFile => {

									},
								);
							}
							if (this.filesToUpload.length != 0) {
								this.knowledgeService.makeFileRequest([], this.filesToUpload, data.knowledgeId, '')
									.subscribe(
									dataFile => {

									},
								);
							}
							this.isLoading = false
							knowledge.id = data.knowledgeId
							this.checkJPRequest(knowledge, data)
							// this.clearForm();
							this.addKnowledgeForm.get('type').setValidators([]);
							this.addKnowledgeForm.get('trueorfalse').setValidators([]);
							this.addKnowledgeForm.get('type').updateValueAndValidity();
							this.addKnowledgeForm.get('trueorfalse').updateValueAndValidity();

						},
						error => {
							this.isLoading = false
							this.alertService.clear();
							let err = error.json();
							this.alertService.handleError(err);
						}
						);
				}
			}
		} else {
			this.alertService.clear();
			this.alertService.error('Same Answers exist.');
		}

	}

	save() {
		this.answerArray = [];
		this.ansExists = true;
		let answerControls = <FormArray>this.addKnowledgeForm.controls['answers'];
		let visualAnswerControls = <FormArray>this.addKnowledgeForm.controls['visualAnswers'];
		let count = 0;
		if (answerControls.length != 0) {
			for (var i = 0; i < answerControls.length; i++) {
				let value = this.addKnowledgeForm.controls.answers.value[i].isCorrect === true ? 1 : 0;
				let answer = this.addKnowledgeForm.controls.answers.value[i].answer;
				let fullAnswer = new KnowledgeAnswer(this.addKnowledgeForm.controls.answers.value[i].answer, value);
				if (this.answerArray.length == 0) {
					this.answerArray.push(fullAnswer);
				} else if (!this.isAnswerAlreadyExists(this.answerArray, answer)) {
					this.answerArray.push(fullAnswer);
				} else {
					count++;
				}
			}
		} else if (visualAnswerControls.length != 0) {
			for (var i = 0; i < visualAnswerControls.length; i++) {
				let value = this.addKnowledgeForm.controls.visualAnswers.value[i].isCorrectAnswer === true ? 1 : 0;
				let answer = this.addKnowledgeForm.controls.visualAnswers.value[i].visualTextAnswer;
				let fullAnswer = new KnowledgeAnswer(this.addKnowledgeForm.controls.visualAnswers.value[i].visualTextAnswer, value);
				if (this.answerArray.length == 0) {
					this.answerArray.push(fullAnswer);
				} else if (!this.isAnswerAlreadyExists(this.answerArray, answer)) {
					this.answerArray.push(fullAnswer);
				} else {
					count++;
				}
			}
		} else {

		}

		if (count > 0) {
			this.ansExists = false
		}
		if (this.ansExists) {

			let orgId = null;
			if (this.addKnowledgeForm.get("orgId").value != null) {
				if (this.addKnowledgeForm.get("orgId").value.length > 0) {
					orgId = this.addKnowledgeForm.get("orgId").value[0].id;
				}
			}

			let isTestQue = this.addKnowledgeForm.value.isTestQuestion === true ? 1 : 0;
			let isVisualQuestion = this.addKnowledgeForm.value.isVisualQuestion === true ? 1 : 0;
			let knowledge = new Knowledge(this.addKnowledgeForm.value.knowledge, isTestQue);
			knowledge.isVisualQuestion = isVisualQuestion
			if (this.addKnowledgeForm.value.type != "") {
				knowledge.type = this.addKnowledgeForm.value.type;
			} else if (this.addKnowledgeForm.value.visualtypeOpt != "") {
				knowledge.type = this.addKnowledgeForm.value.visualtypeOpt
			} else {
				knowledge.type = ""
			}
			knowledge.correctAnswer = this.addKnowledgeForm.value.trueorfalse === "true" ? 1 : 0;
			knowledge.correctAnswer = this.addKnowledgeForm.value.type != "Boolean" ? null : knowledge.correctAnswer;
			knowledge.orgCode = orgId

			knowledge.tagList = this.selectedTags;
			if (this.filesToUpload.length != 0) {
				knowledge.isImageAttached = 1
			} else {
				knowledge.isImageAttached = 0
			}
			if (this.filesToUpload.length != 0) {
				knowledge.fileType = this.addKnowledgeForm.value.fileType
			} else {
				knowledge.fileType = this.addKnowledgeForm.value.fileType
			}

			let ansArray: Array<KnowledgeAnswer> = [];
			let answerControls = <FormArray>this.addKnowledgeForm.controls['answers'];
			let visualAnswerControls = <FormArray>this.addKnowledgeForm.controls['visualAnswers'];
			let count = 0;
			if (answerControls.length != 0) {
				for (var i = 0; i < answerControls.length; i++) {
					let value = this.addKnowledgeForm.controls.answers.value[i].isCorrect === true ? 1 : 0;
					if (value == 1) {
						count++;
					}
					//console.log(this.addKnowledgeForm.controls.answers.value[i].answer + " "+ value);
					let curAnswer = new KnowledgeAnswer(this.addKnowledgeForm.controls.answers.value[i].answer, value);
					ansArray.push(curAnswer);
				}
			} else if (visualAnswerControls.length != 0) {
				for (var i = 0; i < visualAnswerControls.length; i++) {
					let value = this.addKnowledgeForm.controls.visualAnswers.value[i].isCorrectAnswer === true ? 1 : 0;
					if (value == 1) {
						count++;
					}
					let answer = this.addKnowledgeForm.controls.visualAnswers.value[i].visualTextAnswer
					let fileType = this.addKnowledgeForm.controls.visualAnswers.value[i].answerFileType
					if (answer != '') {
						let curAnswer = new KnowledgeAnswer(answer, value, '', '', '');
						ansArray.push(curAnswer);
					} else {
						let curAnswer = new KnowledgeAnswer(answer, value);
						curAnswer.answerFileType = fileType
						ansArray.push(curAnswer);
					}
				}
			} else {

			}

			if (count > 0) {
				this.isSelected = true;
			} else {
				this.isSelected = false;
				if (knowledge.type == "Boolean" || knowledge.isTestQuestion == 0) {
					this.isSelected = true;
				}
				else if (knowledge.type == "Single" && this.checkedRadioIndex != -1) {
					ansArray[this.checkedRadioIndex].isCorrect = 1;
					this.isSelected = true;
				}
			}

			if (count < 2 && knowledge.type == "Multiple") {
				this.isSelectedMultiple = false;
			} else {
				this.isSelectedMultiple = true;
			}

			if (this.isSelected) {
				if (this.isSelectedMultiple) {
					this.knowledgeService.addKnowledge(knowledge, ansArray, [], [], '')
						.subscribe(
						data => {
							if (this.fileList.length != 0) {
								this.knowledgeService.makeFileRequests([], this.fileList, data.knowledgeId, data.answerList)
									.subscribe(
									dataFile => {

									},
									error => {
										this.isLoading = false
										this.alertService.clear();
										let err = error.json();
										this.alertService.handleError(err);
									}
									);

							}
							if (this.filesToUpload.length != 0) {
								this.knowledgeService.makeFileRequest([], this.filesToUpload, data.knowledgeId, '')
									.subscribe(
									dataFile => {

									},
									error => {
										this.isLoading = false
										this.alertService.clear();
										let err = error.json();
										this.alertService.handleError(err);
									}
									);

							}
							this.isLoading = false
							knowledge.id = data.knowledgeId
							// this.checkJPRequestSaveAndClose(knowledge, data)
							this.checkJPRequestSave(knowledge, data);
							// this.clearForm();
							this.addKnowledgeForm.get('type').setValidators([]);
							this.addKnowledgeForm.get('trueorfalse').setValidators([]);
							this.addKnowledgeForm.get('type').updateValueAndValidity();
							this.addKnowledgeForm.get('trueorfalse').updateValueAndValidity();

						},
						error => {
							this.isLoading = false
							this.alertService.clear();
							let err = error.json();
							this.alertService.handleError(err);
						}
						);
				}
			}
		} else {
			this.alertService.clear();
			this.alertService.error('Same Answers exist.');
		}
	}

	public isAnswerAlreadyExists(list: any[], value: string): boolean {
		var isExists = false;
		list.forEach(a => {
			if (a.answer == value) {
				if (a.answer) {
					isExists = true;
				} else {
					isExists = false;
				}
			}
		});
		return isExists;
	}

	//check whether add knowlede from job profile screen
	public checkJPRequest(kno: Knowledge, data: any) {
		if (this.selectedMenuItem == 'AddJobProfile') {
			this.addKnowledgeToJP(kno)
		} else {
			this.alertService.clear();
			this.alertService.success('Successfully added the knowledge.');
			// this.clearForm()

			// this.arrayOfStrings = [];
			// for (var i=0; i < data.knowledge.length; i++) {
			//     this.arrayOfStrings.push(data.knowledge[i].Knowledge);
			// }
			// $("#knowledge").autocomplete({ source : this.arrayOfStrings});
		}
	}

	public checkJPRequestSave(kno: Knowledge, data: any) {
		if (this.selectedMenuItem == 'AddJobProfile') {
			this.addKnowledgeToJPSave(kno)
		} else {
			this.alertService.clear();
			this.cancel();
		}
	}

	//add knowledge to job profile
	public addKnowledgeToJP(kno: Knowledge) {
		let jp: JobProfile = this.jobProfileService.getSelectedJobProfileForEdit()
		let cc: CoreCompetency = this.jobProfileService.getSelectedJPCCModel()
		let task: Task = this.jobProfileService.getSelectedTaskModel()
		let i = task.knowledgeList.length
		kno.orderNum = i++
		kno.remediationList = []


		let tempJP: JobProfile = new JobProfile(jp.jobProfileId)
		let tempTask: Task = new Task(task.id, task.text, true, [], [], [])
		tempTask.knowledgeList.push(kno)
		let tempCC: CoreCompetency = new CoreCompetency(cc.id, cc.text)
		tempCC.taskList = tempTask
		tempJP.coreCompetencyList = tempCC

		this.jobProfileService.addKnowledgeToJobProfile(tempJP)
			.subscribe(
			data => {
				if (kno.isTestQuestion == 1) {
					task.knowledgeQuestionList.push(kno)
				} else {
					task.knowledgeList.push(kno)
				}
				this.alertService.clear();
				this.alertService.success('Successfully added Knowledge to the job profile.');
				// this.clearForm()
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
			)

	}

	public addKnowledgeToJPSave(kno: Knowledge) {
		let jp: JobProfile = this.jobProfileService.getSelectedJobProfileForEdit()
		let cc: CoreCompetency = this.jobProfileService.getSelectedJPCCModel()
		let task: Task = this.jobProfileService.getSelectedTaskModel()
		let i = task.knowledgeList.length
		kno.orderNum = i++
		kno.remediationList = []


		let tempJP: JobProfile = new JobProfile(jp.jobProfileId)
		let tempTask: Task = new Task(task.id, task.text, true, [], [], [])
		tempTask.knowledgeList.push(kno)
		let tempCC: CoreCompetency = new CoreCompetency(cc.id, cc.text)
		tempCC.taskList = tempTask
		tempJP.coreCompetencyList = tempCC

		this.jobProfileService.addKnowledgeToJobProfile(tempJP)
			.subscribe(
			data => {
				if (kno.isTestQuestion == 1) {
					task.knowledgeQuestionList.push(kno)
				} else {
					task.knowledgeList.push(kno)
				}
				this.alertService.clear();
				this.cancel();
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
			)

	}

	//clear form data
	// public clearForm() {
	// 	this.addKnowledgeForm.reset();
	// 	this.removeall();
	// 	this.showTypeDiv = false;
	// 	this.showAnswers = false;
	// 	this.showBooleanDiv = false;
	// 	this.showRadioBtns = false;
	// 	this.selectedOrgId = null;
	// }

	cancel() {
		if (this.selectedMenuItem == 'AddJobProfile') {
			this.sharedService.hideComponent('AddJobProfile');
		} else {
			this.sharedService.change('Knowledge');
		}
	}

	public loadActiveKnowledgeTags() {
		this.knowledgeTagService.getActiveKnowledgeTags()
			.subscribe(
			data => {
				//console.log('[loadActiveKnowledgeTags] ' + JSON.stringify(data).toString());
				this.autocompleteItemsAsObjects = []
				data.ccTagList.forEach(element => {
					let item = { value: element.Description, id: element.Id }
					this.autocompleteItemsAsObjects.push(item);
				});
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			});
	}

	public addTags(event: any) {
		//console.log('[addTags]' + JSON.stringify(event).toString());
		this.selectedTags.push(event);
	};

	public removeTags(event: any) {
		//console.log('[removeTags]' + JSON.stringify(event).toString());
		this.selectedTags = this.selectedTags.filter(tag => tag.id !== event.id);
	};

	public removeImage(element) {
		element.value = ""
		this.showQuestionImage = false
		this.addKnowledgeForm.get("fileType").setValue("");
	}
}
